import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import 'normalize.css';

import Footer from '../components/Footer';
import Overlay from '../components/Overlay';
import CookiesTooltip from '../components/CookiesTooltip';

import './index.sass';

function Layout({ children }) {
  const { t } = useTranslation();

  return (
    <>
      <Overlay>
        <p className="title">{t('dialogTitle1')}<strong>{t('dialogTitle2')}</strong>{t('dialogTitle3')}</p>
        <p className="text">{t('dialogText1')}<strong>{t('dialogText2')}</strong>{t('dialogText3')}</p>
      </Overlay>
      {children}
      <Footer />
      <CookiesTooltip />
    </>
  );
}

export default Layout;