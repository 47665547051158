import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql } from 'gatsby';


const Footer = () => {
  const { t } = useTranslation();
  const { siteBuildMetadata: { buildTime } } = useStaticQuery(graphql`
    query {
      siteBuildMetadata {
        buildTime(formatString: "YYYY")
      }
    }
  `);

  return (
    <footer>
      <p className="textAlignCenter">
        © {buildTime} <span className="textColorAccent">GetNeosurf</span>. {t('rightsReserved')}
      </p>
    </footer>
  );
};

export default Footer;