import React, { useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';


const storageKey = 'cookiesOk';

function CookiesTooltip() {
  const { t } = useTranslation();
  const [ hide, setHide ] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem(storageKey)) {
      setHide(false);
    }
  }, [])

  if (hide) {
    return null;
  }

  return (
    <div className="tooltip">
      <p>{t('cookiesMessage')}</p>
      <span role="button" className="button" onClick={() => {
        setHide(true);
        localStorage.setItem(storageKey, true);
      }}>
        OK
      </span>
    </div>
  );
}

export default CookiesTooltip;