import { useRef, useCallback, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';


function useNewsletter() {
  const [ responseMsg, setResponseMsg ] = useState();
  const inputEl = useRef(null);
  const handleSubmit = useCallback(async ev => {
    ev.preventDefault();

    const { msg } = await addToMailchimp(inputEl.current.value);

    setResponseMsg(msg);
  }, []);

  return {
    handleSubmit,
    inputEl,
    responseMsg
  };
}

export default useNewsletter;
